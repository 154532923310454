import React from "react";
import Carousel from "../src/components/C2";
import { Card, CardBody } from '@windmill/react-ui';
import Fade from 'react-reveal/Fade';
import image from './assets/Edinburgh-Property-management.jpg'
const Services = () => {
  const services = [
    {
      title: "Architectural Design Solutions",
      description: "Where Imagination Meets Reality. Our creative minds and our technical experts collaborate to craft visionary structures that push the boundaries of design. Eventually merging form with function to create lasting impressions.",
      image: "https://images.unsplash.com/photo-1608590752210-8b54df0bc20e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80",
    },
    {
      title: "Interior Design Solutions",
      description: "Crafting Spaces with Elegance Our interior design expertise transforms environments into elegant, functional, and unique spaces that resonate with occupants.",
      image: "https://images.unsplash.com/photo-1611048267604-2e59a19a4f97?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80",
    },
    {
      title: "2D/3D Design",
      description: "Visualizing Ideas in Precision Our skilled designers transform concepts into vivid 2D and 3D renderings, providing a clear picture to visualise the project that aspires to perfection.",
      image: "https://cdn3.f-cdn.com//files/download/22385692/3D_Floor_Plan_Low%20Res.jpg?width=780&height=593&fit=crop",
    },
    {
      title: "Structrual Solutions",
      description: "Our expert structural engineers ensure the strength and stability of every project, supporting architectural brilliance with solid and dependable construction.",
      image: "https://damassets.autodesk.net/content/dam/autodesk/images/solutions/cad-software/compatibility-thumb-600x300.png",
    },
    {
      title: "Property Management",
      description: "Unlock the potential of your property with our expert management services, tailored to maximize your returns. From tax payments to proactive maintenance, seamless renting, leasing, and beyond – we've got every aspect of your property covered, ensuring your investments thrive.",
      image: image,
    },
    {
      title: "Residential Construction",
      description: "We specialize in creating your dream, blending luxury, comfort, and aesthetics to make your ideal home a reality.Assuring best in quality, pricing & timebound.",
      image: "https://s3da-design.com/wp-content/uploads/2018/10/How-to-estimate-residential-construction-cost-and-home-design-guide.jpg",
    },
    {
      title: "Commercial Construction",
      description: "Our expertise in commercial construction creates dynamic workspaces and thriving retail environments, setting the stage for business excellence.",
      image: "https://5.imimg.com/data5/SELLER/Default/2021/8/EI/GP/YX/54015206/commercial-raj-kamal-500x500.png",
    },
    {
      title: "Industrial Construction",
      description: "We specialize in constructing robust industrial facilities, providing the infrastructure essential for industrial growth and progress.",
      image: "https://www.onyconinfra.com/images/indstr-build3.png",
    },

  ];

  return (
    <div>
      <div>
       
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mt-8 mr-6 ml-6">
        {services.map((service, index) => (
          <Fade bottom key={index}>
            <Card className="shadow-lg shadow-gray-600">
              <img className="object-cover h-64 w-full" src={service.image} alt={service.title} />
              <CardBody>
                <h2 className="mb-4 font-bold text-gray-900 dark:text-gray-300">{service.title}</h2>
                <p className="text-gray-600 dark:text-gray-400">{service.description}</p>
              </CardBody>
            </Card>
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default Services;
