import React from "react";
import backgroundImageURL from './assets/2950171-removebg-preview.png';
import Fade from 'react-reveal/Fade';
import ReactGA from 'react-ga'; // Import ReactGA
import { Link } from "react-router-dom";

const AboutUs = () => {
  ReactGA.initialize('G-3B1EJBPJBH'); // Initialize Google Analytics with your tracking ID

  const trackButtonClicked = () => {
    // Track button click in Google Analytics
    ReactGA.event({
      category: 'Button Click',
      action: 'Click',
      label: 'Know More Button', // Use a descriptive label for your button
    });
  };

  return (
    <div>
      <Fade bottom cascade>
        <div className="py-8 sm:py-6  ">
          <div className="mx-auto ml-md px-10 lg:px-8 ">
            <div className="lg:flex lg:justify-center item-center  sm:justify-center item-center">
              <div className="lg:w-1/2 lg:pr-10">
                <h2 className="text-base font-semibold leading-7 text-gray-900">About Us</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Welcome to Paramount Enterprises – Construction par Excellence
                </p>
                <p className="mt-6 text-xl leading-8 text-gray-900 lg:text-left ">
                  At Paramount Enterprises, we transform your architectural visions into tangible marvels. With a legacy of excellence in construction, our team is dedicated to delivering projects that stand as a testimony to creativity, quality, and cost-effectiveness.
                </p>
                <br />
                <br />
                <Link to="/about"> {/* Use Link component */}
          <button
            onClick={() => {
              trackButtonClicked('Explore Services Button');
            }}
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Know More
          </button>
        </Link>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default AboutUs;
