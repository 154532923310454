import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import ReactGA from 'react-ga'; // Import ReactGA
import logo from "../assets/Bidyuth advertisement.jpg";
import { Link } from 'react-router-dom';

const Hero = () => {
  ReactGA.initialize('YOUR_TRACKING_ID'); // Initialize Google Analytics with your tracking ID

  const trackButtonClicked = (buttonName) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'Click',
      label: `${buttonName} Button`,
    });
  };
  
  const trackButtonClick = (label) => {
    // Track button clicks in Google Analytics
    ReactGA.event({
      category: 'Button Click',
      action: 'Click',
      label: label, // Use a descriptive label for your button
    });
  };

  const trackLinkClick = (label) => {
    // Track link clicks in Google Analytics
    ReactGA.event({
      category: 'Link Click',
      action: 'Click',
      label: label, // Use a descriptive label for your link
    });
  };

  return (
    <Fade bottom cascade>
      <section
        className="py-32 px-8 text-white relative"
        style={{
          backgroundImage: "url('https://projex.com.au/wp-content/uploads/2018/05/projex-From-Foundations-to-the-Roof.jpg",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-32 relative">
          
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-900 ring-1 ring-gray-900/10 hover:ring-indigo-900">
              <b>Construction par Excellence: Your dreams made a reality.</b>{' '}
              <Link
                to="/about"
                className="font-semibold text-indigo-600"
                onClick={() => trackButtonClick('Read More Button')} // Track button click
              >
                <span className="absolute inset-0" aria-hidden="true" />
                Read more <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
          <div className="text-center">
            <h4 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Welcome to Multiconcepts, your trusted partner for all your construction needs.
            </h4>
            <p className="mt-6 text-lg leading-8 text-gray-900">
              <b>With years of experience in this industry, dedicated to delivering outstanding construction projects that exceed all expectations.</b>
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/services"> {/* Use Link component */}
          <button
            onClick={() => {
              trackButtonClicked('Explore Services Button');
            }}
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Explore Our Services
          </button>
        </Link>
              <Link
                to="/gallery"
                className="text-sm font-semibold leading-6 text-gray-900"
                onClick={() => {
                  trackLinkClick('Our Projects Link'); // Track link click
                }}
              >
                Our projects <span aria-hidden="true">→</span>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </section>
    </Fade>
  );
};

export default Hero;
