import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('G-3B1EJBPJBH', { debug: true });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
  
);