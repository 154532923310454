import HomePage from './homepage';
import Navbar from './navbar';
import Footer from './footer';
import AboutUs from './Aboutus';
import Services from './Services';
import Gallery from './gallery';
import Hafele from './hafele';
import {Route, Routes , redirect  } from 'react-router-dom';
import ReactGA from 'react-ga';
ReactGA.initialize('G-3B1EJBPJBH');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path='about' element={<AboutUs />} />
        <Route path='services' element={<Services />} />
        <Route path='gallery' element={<Gallery />} />
        <Route path='hafele' element={<Hafele />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
