import React, { useState } from "react";
import Carousel from "./carousel";
import i1 from "./assets/1.jpg";
import i2 from "./assets/2.jpg";
import i3 from "./assets/3.jpg";
import i4 from "./assets/4.jpg";
import i5 from "./assets/5.jpg";
import i6 from "./assets/6.jpg";
import i7 from "./assets/7.jpg";
import i8 from "./assets/8.jpg";
import i9 from "./assets/9.jpg";
import i10 from "./assets/10.jpg";
import i11 from "./assets/11.jpg";
import i12 from "./assets/12.jpg";


const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div>

        
<Carousel />



      {/* Category Buttons */}
      <div className="flex items-center justify-center py-4 md:py-8 flex-wrap ">
        <button type="button" onClick={() => handleCategoryChange("all")} className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 ${selectedCategory === "all" ? "text-blue-500 bg-blue-700" : "dark:text-white dark:focus:ring-gray-800"}`}>All categories</button>
        <button type="button" onClick={() => handleCategoryChange("Residential")} className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 ${selectedCategory === "shoes" ? "text-blue-500 bg-blue-700" : "dark:text-white dark:focus:ring-gray-800"}`}>Residential</button>
        <button type="button" onClick={() => handleCategoryChange("Commercial")} className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 ${selectedCategory === "bags" ? "text-blue-500 bg-blue-700" : "dark:text-white dark:focus:ring-gray-800"}`}>Commercial</button>
        <button type="button" onClick={() => handleCategoryChange("Appartment")} className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 ${selectedCategory === "electronics" ? "text-blue-500 bg-blue-700" : "dark:text-white dark:focus:ring-gray-800"}`}>Appartment</button>
        <button type="button" onClick={() => handleCategoryChange("Interiors")} className={`text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 ${selectedCategory === "gaming" ? "text-blue-500 bg-blue-700" : "dark:text-white dark:focus:ring-gray-800"}`}>Interiors</button>
      </div>
      
      {/* Image Gallery */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 object-wrap">
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
           <img className="object-cover h-auto w-full rounded-lg" src={i1} alt="Gallery Image 1" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
          <img className="object-cover h-auto w-full rounded-lg" src={i2} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
          <img className="object-cover h-auto w-full rounded-lg" src={i3} alt="Gallery Image 2" />
        </div></div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
          <img className="object-cover h-auto w-full rounded-lg" src={i4} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
          <img className="object-cover h-auto w-full rounded-lg" src={i5} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
          <img className="object-cover h-auto w-full rounded-lg" src={i6} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
          <img className="object-cover h-auto w-full rounded-lg" src={i7} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
          <img className="object-cover h-auto w-full rounded-lg" src={i8} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
        <div>
          <img className="object-contain h-auto w-full rounded-lg" src={i2} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Appartment" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
          <img className="object-contain h-auto w-full rounded-lg" src={i10} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Appartment" ? "" : "hidden"}`}>
        <div className="overflow-hidden">
          <img className="object-contain h-auto w-full rounded-lg" src={i11} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Appartment" ? "" : "hidden"}`}>
        <div >
          <img className="object-cover h-auto w-full rounded-lg" src={i12} alt="Gallery Image 2" />
        </div>
        </div>
        <div className={`gallery-item ${selectedCategory === "all" || selectedCategory === "Residential" ? "" : "hidden"}`}>
          <div className="overflow-hidden">
            <img className="object-cover h-auto w-full rounded-lg" src={i9} alt="Gallery Image 3" />
        </div>
        </div>
        {/* ... Add more gallery images ... */}
      </div>
    </div>
  );
};

export default Gallery;
